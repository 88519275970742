
#card-header--wrap{
	.card-header[category="stock"]{
		background: linear-gradient(320deg, #121831 0%, #936ce5 100%);
	}

	.card-header[category="economic"] {
		background: linear-gradient(320deg, #121831 0%, #d1347c 100%);
	}
	.card-header[category="commodity"]{
		background: linear-gradient(320deg, #121831 0%, #16a0d2 100%);
	}


    .ticker{
        color: #9a9a9a;
    }
    .description{
        color: #fff;
        font-weight: 100;
    }
		.action-button{
			margin: 5px 8px 5px 8px !important;
			padding: 0px !important;
		}
}

#card-body--wrap{
		.card-body{
				background-color: #27293D;
		}
}

#card--wrap{
	.card{
		width: 100%;
		height: 380px;
		background: #27293d;
	}

	/* 화면 크기가 xs일 때 높이를 늘리는 스타일 */
	@media (max-width: 992px) {
		.card {
			height: 650px; /* xs 화면 크기에 맞게 높이를 늘림 */
		}
	}
	.card-body{
		padding: 0px 0px 0px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30vh;
		background: #27293d;
	}

	.error-col{
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
	}
}
