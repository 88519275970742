.detail-modal .modal-dialog{
	height: 80vh;
	width:  80vw;
	--bs-modal-width: 80vw;
 	
}
.detail-modal .modal-dialog .modal-content{
	height: 80vh;
	width:  80vw; 

}

.detail-modal-body{
	background: #f8f9fa;
	background-clip: border-box;
	border-radius: 10px;
	text-align: left;
	margin: 10px 30px 10px 30px;
	height: auto;
	width: auto;
	overflow: auto;
}
.custom-modal{
	height: 80vh;
	width: 80vw;
}
