body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  text-align: left;
  background-color: #1e1e2f;
}

.main-panel {
    position: relative;
    float: right;
    width: 100%;
    min-height: 100vh;
    border-top: 2px solid #e14eca;
    /*background: linear-gradient(#3636c5, #18181d);*/
    transition: all 0.5s ;
    transform: translateX(0px);  
  }

  .main-panel.isOpen {
    transform: translateX(260px);  /* isOpen이 true일 때 transform 속성을 변경합니다 */
  }

