.signin-background {
    width: 100%;
    height: 100vh;
    background-color: #121212;
    position: fixed;
}


.wrapper{
    position: absolute;
    height: 600px;
    width: 460px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 390px;
    background-color: rgba(255,255,255,0.13);
    padding: 0px 30px 0px 30px;
    border-radius: 15px;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    z-index: 1;
  }

.title-text{
    overflow: hidden;
    max-width: calc(100% - 60px);
    position: absolute;
    display: flex;
    width: 100%;
    z-index: 1;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    padding: 20px 0;
  }

.title{
    width: 70%;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
    z-index: 1;
    color: #ffffff;
}

.slide-controls{
    position: absolute;
    display: flex;
    height: 50px;
    width: calc(100% - 60px);
    overflow: hidden;
    margin: 90px 0 10px 0;
    justify-content: space-between;
    border: 1px solid lightgrey;
    border-radius: 15px;
    z-index: 1;
}

.slide{
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    z-index: 30;
    transition: all 0.6s ease;
}

.slider-tab{
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    z-index: 0;
    border-radius: 15px;
    background: -webkit-linear-gradient(left,#0073e6,#0059b3,#0059b3
  , #003366);
    transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
  }

  .slideRight {
    left: 50%; /* Change the left property to 50% */
    background: -webkit-linear-gradient(left,#662e00,#b35100,#b36500
    , #e65c00);
  }


.first-circle {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(
        #1845ad,
        #23a2f6
    );
    left: calc(50% - 320px);
    top: calc(50% - 405px);
    z-index: 0;
}
.second-circle{
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(
        to right,
        #ff512f,
        #f09819
    );
    right: calc(50% - 320px);
    bottom: calc(50% - 405px);
    z-index: -1;
}
.login-form{
    width: calc(100% - 80px);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    padding: 50px 35px;
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.signup-form{
    width: calc(100% - 80px);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 55%;
    left: 50%;
    border-radius: 10px;
    padding: 50px 35px;
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.input-box{
    display: block;
    height: 50px;
    width: calc(100% - 25px);
    background-color: rgba(255,255,255,0.07);
    border-radius: 10px;
    padding: 0 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
}
::placeholder{
    color: #e5e5e5;
}


.description-message{
    font-size: 5px;
    color: #ffffff;
}




.auth-button{
    margin-top: 25px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    transition: 0.4s;

}

.login-active-button {
    margin-top: 25px;
    width: 100%;
    background: -webkit-linear-gradient(left,#0073e6,#0059b3,#0059b3
    , #0073e6);
        color: white; /* White text color */
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: 0.4s;
  }


.signup-active-button {
    
    margin-top: 25px;
    width: 100%;
    background: -webkit-linear-gradient(left,#e65c00,#b35100,#b36500
    , #e65c00);
        color: white; /* White text color */
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: 0.4s;
  }