
body {
	width: 100%;
	height: 100%;
}

.modal-background {
	position: fixed;
	width: 356%;
	height: 100vh;
	top: calc(50% - 450px);
	left: calc(50% - 800px);
	background: rgba(0, 0, 0, 0.8);
	transition: opacity 0.3s;
	z-index: 999; /* Ensure the modal is on top */
	display: flex;
	align-items: center;
	justify-content: center;
}



.modal-container {
	/* 모달창 크기 */
	width: 350px;
	height: 100px;

	/* 최상단 위치 */
	z-index: 999;

	/* 중앙 배치 */
	/* top, bottom, left, right 는 브라우저 기준으로 작동한다. */
	/* translate는 본인의 크기 기준으로 작동한다. */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	/* 모달창 디자인 */
	background-color: rgb(255, 255, 255);
	border: 1px solid rgb(5, 5, 5);
	border-radius: 8px;
	justify-content: center;
	align-items: center;
}

/* 모달창 내부 X버튼 */
.close {
	background: rgb(0, 0, 0);
	color: white;
	padding: .5em 1em;
	border-radius: 4px;
	position: absolute;
	left: 50%; /* Set it to the center horizontally */
	transform: translateX(-50%); /* Center it horizontally */
	bottom: 10px; /* Position it at the bottom */
	cursor: pointer;
}


