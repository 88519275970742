.navbar {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 30px 10px 15px;
    background-color: transparent;  /* 투명한 배경색 */
    border-bottom: 1px solid #1f87f8;  /* 1px의 테두리 선 (원하는 색상으로 수정) */
    position: relative;
    width: 100%;
    height: 37px;
    z-index: 1;  /* 다른 요소 위에 나타나도록 z-index 설정 */
  }
  
  /* Navbar의 투명한 배경색이 흰색일 때의 스타일 */
.navbar.transparent {
  background-color: rgba(255, 255, 255, 0.8);  /* 흰색의 투명한 배경색 */
}

.brand {
  position: relative;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #fff;
  margin-left: 17px;
  text-transform: uppercase;
  font-size: 1rem;
}

.navbar a:link,
.navbar a:visited,
.navbar a:hover,
.navbar a:active {
  text-decoration: none;
}


.icon-container{
    &:hover {
        cursor: pointer;
        font-weight: bold; /* 아이콘의 두께를 두꺼워지게 함 */
        /* 다른 hover 시에 적용될 스타일들 */
      }
}
