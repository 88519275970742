.container{
    margin: 0px 20px 0px 20px  !important;
    width: 100% !important;
    max-width: 100vw !important;
}
.wrapper{
    display: flex;
}
.divider{
	display: flex;
	align-content: center;
	justify-content: space-between;
	align-items: flex-end;

	width: 100%;
	height: 40px; /* 구분선의 높이 설정 */
	background-color: #1e1e2f; 
	margin: 10px 0; 
}

.divider .text {
	color: #fff;
	position: relative;
}


.divider .text:after {
	background: #145faa;
	border-radius: 8px;
	content: "";
	display: block;
	height: calc(100% - 5px);
	left: 0;
	position: absolute;
	top: 5px;
	width: 5px;
}
