body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525f7f;
    text-align: left;
    background-color: #1e1e2f;
  }

p {
  margin-top: 0;
  margin-bottom: 0;
}