/*

1. css파일이 여러개 적용 되어있는 상태라면 가장 뒤에 있는 파일의 css속성이 우선 적용
2. css는 선택자에 따라 우선순위가 존재
css는 기본적으로 가장 나중에 쓴 속성이 우선적용되고, 
!important > inline style > id > class , 수도클래스 > tag , 수도엘리먼트 순으로 우선순위를 가짐

3. css는 우선도 점수가 존재
    인라인 스타일 = 1000점
    id = 100점
    클래스 , 수도클래스 = 10점
    태그선택자 , 수도엘리먼트 = 1점
    ex) id태그내에 클래스를 사용하면 110점을 가지게 됨
*/
/*
    module.css파일은 뒤에 동적인자가 무조건붙음 (id, class, style 이든 상관없이)
    module.css은 className만 사용해야됨
    reactstrap을 상속하기 위한 css는 chart.css로 동적인자를 제거한 css파일 사용
*/

.card{
    width: 100%;
    height: 380px;
    background: #27293d;
}

/* 화면 크기가 xs일 때 높이를 늘리는 스타일 */
@media (max-width: 992px) {
    .card {
        height: 650px; /* xs 화면 크기에 맞게 높이를 늘림 */
    }
}

.action-button{
    margin: 5px 8px 5px 8px !important;
    padding: 0px !important;
}

.card-body{
    padding: 0px 0px 0px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh !important;
	background: #27293d;
}

.error-col{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

