.sidebar{
  position: relative;
  width: 260px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0px;
  position: fixed;
  transition: all 0.5s;
  z-index: 1;
  transform: translateX(-260px);  
}
.sidebar[data="blue"] {
  background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
}
.sidebar[data="green"]{
  background: linear-gradient(0deg, #0098f0 0%, #00f2c3 100%);
}

.sidebar.isOpen {
  transform: translateX(0px);  /* isOpen이 true일 때 transform 속성을 변경합니다 */
}


.sidebar-menu{
  width: 260px;
  padding-left: 0%;
  margin-top: 0;
}

.info{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
  transition: inherit;
  padding: 8px 11px 8px 11px;
  height: 46px;
  transition: inherit
}
.info:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 1px; /* 높이 조절을 통해 선의 굵기를 조정할 수 있습니다. */
  background-color:hsla(0, 0%, 100%, 0.644); 
}


.id{
  text-transform: uppercase;
  padding: 0.5rem 0;
  display: block;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
  transition: inherit;
}

.sidebar-text{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 8px 10px 8px;
  margin: 10px 15px 0px 15px;
  list-style: none;
  height: 30px;
  width: 230px;
  transition: inherit;
}

.sidebar-text a {
  text-decoration: none;
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0%;
  color: hsla(0,0%,100%,.8);
}
.sidebar-text :hover{
  color: #fff;
  font-weight: bold;
}


.sidebar-logout{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 8px 10px 8px;
  margin: 10px 15px 0px 15px;
  height: 30px;
  width: 230px;
  transition: inherit;
  cursor: pointer;
  color: hsla(0,0%,100%,.8);
}
.sidebar-logout :hover{
  color: #fff;
  font-weight: bold;
}